import React from "react";
import styles from "../styles/Home.module.css";
import TokenSearchBar from "../components/TokenSearchBar";

export default function Home({ stats, hmac }) {
    return (
        <div className={styles.home}>
            <object data="logo2.svg" type="image/svg+xml" className={styles.mainLogo}></object>
            <div style={{ height: "40vh" }}>
                <div style={{ textAlign: "center" }}>
                    Actively monitoring <span className={styles.solidusYellow}>{stats?.numContracts.toLocaleString()}</span> tokens
                    <br />
                    and <span className={styles.solidusYellow}>{stats?.numScams.toLocaleString()}</span> scams
                </div>
                <div style={{ marginTop: "4em" }}>
                    <TokenSearchBar isMainPage={true} />
                </div>
                <div style={{ marginTop: "4em", textAlign: "center" }}>
                    Powered by{" "}
                    <a className={styles.solidusLink} href="https://www.soliduslabs.com/">
                        Solidus Labs
                    </a>
                </div>
            </div>
            <div className={styles.promotionSection}>
                <div>Automated scam detection, auditing, and metrics</div>
                <div>
                    Support for{" "}
                    <span title="Ethereum, BSC, Base, Polygon, Fantom, Arbitrum, Blast, Optimism, Avalanche, xDai ,Harmony, KCC, Cronos, Oasis">
                        14 chains
                    </span>
                </div>
                <div>Free to use. The first and most used app of its kind</div>
                <div>
                    <a className={styles.link4} styles={{ fontWeight: "bold", color: "#FFCD26" }} href="https://tokensniffer.com/TokenSnifferAPI">
                        API now available!
                    </a>
                </div>
            </div>
        </div>
    );
}

export async function getServerSideProps({ req }) {
    const cache = require("../cache");
    const stats = (await cache.get("stats")) || null;

    return {
        props: {
            stats,
        },
    };
}
